.App {
  text-align: left;
  font-size: 10pt;
}

iframe {
    max-width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  max-width: 1000px;
  margin: 0 auto;
  padding: 2em;
}

.App-link {
  color: #61dafb;
}


* {
    font-size: 14pt;
}

#footer {
  padding-left: 0;
  margin: 0;
  padding-bottom: 2em;
  color: white;

}

#footer a {
  text-decoration: none;
  color: lightblue;
}

#footer a:hover {
  color: blue;
}

a {
  text-decoration: none;
}

#footer-in {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  padding: 2em;
  border-top: solid 2px var(--tucr-lime);
}

#footer li ul{
  padding-left: 0;
}

nav {
  min-height: 10vh;
}

Slider {
  height: 20vh;
}

* {
  font-family: roboto;
  font-weight: 300;
}

header>div>p {
  color: #ddd
}
header>div>h2 {
  color: #ddd
}
header>div>hr {
  border: solid 1px var(--tucr-lime);
  opacity: 1;
}
.shapedividers_com-6437{
  overflow:hidden;
  position:relative;
  }
  .shapedividers_com-6437::before{ 
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 300% 100px;
  background-position: 100% 100%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 476.62 100.69"><path fill="%23232e25" d="M476.62 100.69V0l-238.3 95.47L0 0v100.69Z"/></svg>'); 
  }
  
  @media (min-width:2100px){
  .shapedividers_com-6437::before{
  background-size: 300% calc(2vw + 100px);
  }
  }
   
  .custom-shape-divider-bottom-1676844219 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1676844219 svg {
    position: relative;
    display: block;
    width: calc(154% + 1.3px);
    height: 221px;
}

.custom-shape-divider-bottom-1676844219 .shape-fill {
    fill: #FFFFFF;
}




.tab {
  padding-left: 14em;
}
.h1 {
  text-align: left;
  font-family: 'roboto slab';
  font-weight: 100;
  font-size: xx-large;
  padding-bottom: 0.1em;
}
:root {
  --tucr-lime:#c8f124;
  --tucr-red:#d42232;
  --tucr-green:#7deb30;
  --tucr-darkgreen:#066f41;
  --tucr-dark: #111410 !important; /*#232e25*/;
  --bs-gray-300: #dee2e6;
}
.box {
  margin:15em auto;
}
body {
  background-image: radial-gradient( #334230, var(--tucr-dark) 70%);
}